@import './redactor.css';
@import './choices.css';
@tailwind base;
@tailwind components;

.select2-results__options {
    background-color:white;
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@tailwind utilities;

.button {
    @apply inline-flex items-center px-4 py-2 bg-gray-100 text-gray-800 border border-transparent rounded-md font-semibold text-xs tracking-widest transition ease-in-out duration-150 ring-gray-300;
}

.button:hover {
    @apply bg-gray-200;
}

.button:active, .button:focus {
    @apply bg-gray-300 ring;
}

.button.button-primary {
    @apply bg-highlight_accent text-white !important;
}

.button-primary:hover, .button.button-primary:active, .button.button-primary:focus {
    @apply bg-highlight_accent !important;
}

.button.button-primary:disabled {
    @apply bg-indigo-200 !important;
}

.button.button-danger {
    @apply bg-red-500 text-white !important;
}

.button-danger:hover, .button.button-danger:active, .button.button-danger:focus {
    @apply bg-red-600 !important;
}

.button.button-danger:disabled {
    @apply bg-red-200 !important;
}

[x-cloak] {
    display: none !important;
}

.paragraph_breaks p {
    margin-bottom: 1rem;
}
.paragraph_breaks ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.paragraph_breaks ol {
    display: block;
    list-style: initial !important;
    list-style-type: decimal !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.redactor-box {
    width: 100%;
}
.redactor_text ul,
.redactor-styles ul {
    list-style: initial !important;
}
.redactor_text ol,
.redactor-styles ol {
    list-style: initial !important;
    list-style-type: decimal !important;
}

.redactor-air, .redactor-toolbar {
    z-index: 10;
}

.news_content p {
    margin-bottom: 1rem;
}
.news_content a {
    text-decoration: underline;
}
.news_content a:hover {
    text-decoration: underline;
    color: theme('colors.highlight_accent');
}

.news_content ul {
    margin-left: 1rem;
    list-style: initial !important;
    margin-bottom: 1rem;
}
.news_content ul li {
    margin-left: 1rem;
}

mark{
    background: #ff0;
    color: black;
}
.datepicker-cell {
    line-height: 2rem;
}
.datepicker-cell.prev {
    background-color: #EFEFEF;
    border-radius: unset;
}
.datepicker-cell.next {
    background-color: #EFEFEF;
    border-radius: unset;
}

.record_data ul {
    list-style: inherit !important;
}

.records_table td a p,
.records_table td a ul {
    pointer-events: none;
}
.condensed td a p,
.condensed td a ul {
    pointer-events: none;
}
.condensed td a.py-4 {
  padding-top: theme('spacing.condensed-4');
  padding-bottom: theme('spacing.condensed-4');
}
.condensed td a.px-4 {
  padding-left: theme('spacing.condensed-4');
  padding-right: theme('spacing.condensed-4');
}
.condensed td a.py-6 {
  padding-top: theme('spacing.condensed-6');
  padding-bottom: theme('spacing.condensed-6');
}
.condensed td a.px-6 {
  padding-left: theme('spacing.condensed-6');
  padding-right: theme('spacing.condensed-6');
}
.condensed td {
    /*
    white-space: nowrap;
    overflow: clip;
    */
}
.condensed table img.w-8 {
    width: theme('sizing.condensed-img');
}
.condensed table img.h-8 {
    height: theme('sizing.condensed-img');
}
.condensed table .text-sm {
    font-size: theme('sizing.condensed-font');
    line-height: theme('sizing.condensed-line');
}

.sticky[stuck] {
  box-shadow: 0 0 16px black;
  border-bottom:2px solid #f00;
}
